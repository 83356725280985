import { Actions, SELECTION_TYPES } from "@samedaycustom/types/app";
import { EditItem, FilterValue } from "@samedaycustom/types/app/Operations";
import { ISDCWorkDays } from "@samedaycustom/types/order";
import { ISDCInternalStatusSize, ISDCItem } from "@samedaycustom/types/order/@types/line";
import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import _, { camelCase, isEmpty, isEqual, startCase, transform } from "lodash";
import moment from "moment";
import allSettled from "promise.allsettled";
import { ThunkAction } from "redux-thunk";

import { ISDCWorkPeriod } from "../../../types/order/@types/order.d";
import * as events from "./constants/events";
import { AnyObject } from "@samedaycustom/sdc-types";
import { DateTime } from "luxon";

export { default as Metrics } from "./constants/Metrics.constants";
export { default as componentLoader } from "./fetchChunk";
export * from "./observables";
export { events };
export const tags: {
  [key: string]: {
    text: string;
    color: string;
    backgroundColor: string;
    textStyle?: any;
    icon?: any;
  };
} = {
  "A.A": {
    text: "New order",
    color: "#7A0ECC",
    backgroundColor: "#F2EBFE",
  },
  "A.B": {
    text: "Awaiting Blanks",
    color: "#DE911D",
    backgroundColor: "#FFFBEA",
  },
  "N.S": {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC",
  },
  R: {
    text: "Reviewing",
    color: "#0062CC",
    backgroundColor: "#E5F1FF",
  },
  PROC: {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC",
  },
  "O.H": {
    text: "On hold",
    color: "#DE911D",
    backgroundColor: "#FFFBEA",
  },
  FUL: {
    text: "Fulfilled",
    color: "#0F8613",
    backgroundColor: "#C1F2C7",
  },
  A: {
    text: "Approved",
    color: "#0F8613",
    backgroundColor: "#C1F2C7",
  },
  CAN: {
    text: "Cancelled",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
  },
  DEC: {
    text: "Declined",
    color: "#CF1124",
    backgroundColor: "#FFE3E3",
  },
  "P.S": {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC",
  },
  "P.C": {
    text: "Produced",
    color: "#102A43",
    backgroundColor: "#D9E2EC",
  },
  MS: {
    text: "Multiple Status",
    color: "#8719E0",
    backgroundColor: "#F2EBFE",
  },
  "M.S": {
    text: "Multiple Status",
    color: "#8719E0",
    backgroundColor: "#F2EBFE",
  },
  "R.S": {
    text: "Reviewing Artwork",
    color: "#0062CC",
    backgroundColor: "#E5F1FF",
  },
  "D.S": {
    text: "Partial Delivery",
    color: "#102A43",
    backgroundColor: "#D9E2EC",
  },
  "N.O": {
    text: "New Order",
    color: "#FFFFFF",
    backgroundColor: "#31B237",
  },
  "N.A": {
    text: "Not Available",
    color: "#0062CC",
    backgroundColor: "#E5F1FF",
  },
  S: {
    text: "In Production",
    color: "#102A43",
    backgroundColor: "#D9E2EC",
  },
  "S.A": {
    text: "Reviewing",
    color: "#0062CC",
    backgroundColor: "#E5F1FF",
  },
  C: {
    text: "Approved",
    color: "#0F8613",
    backgroundColor: "#C1F2C7",
  },
  new: {
    text: "NEW",
    color: "#fff",
    backgroundColor: "#18981D",
    textStyle: { fontFamily: "Inter Bold", fontSize: "10px" },
  },
  pickup: {
    text: "Pickup",
    color: "#7A0ECC",
    backgroundColor: "#F2EBFE",
  },
  car: {
    text: "Car Delivery",
    color: "#F0B429",
    backgroundColor: "#FFFBEA",
  },
  shipping: {
    text: "Shipping",
    color: "#006EE5",
    backgroundColor: "#E5F1FF",
  },
};

export const MAX_IMAGE_UPLOAD_SIZE = 1e7;

export const DATE_FORMAT = "DD MMM YYYY";

/** checks if object is an object */
export const isObject = (value) => value !== null && typeof value === "object";

/**
 * checks if @param value is a string
 * @param value
 */
export const isString = (value) => value !== null && typeof value === "string";

export function difference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

export function jsDownload(
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string,
  mime?: string,
  bom?: string
) {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || "application/octet-stream" });
  const mobileNavigatorObject: any = window.navigator;
  if (typeof mobileNavigatorObject.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    mobileNavigatorObject.msSaveBlob(blob, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
}

export const downloadUrl = (file_path) => {
  // try {
  //     Axios.get(file_path).then(response => {
  //         jsDownload(response.data, file_path?.substr(file_path?.lastIndexOf("/") + 1))
  //     })
  // } catch (error) {}
  if (!file_path) return null;
  const a = document.createElement("A");
  a.setAttribute("href", file_path);
  a.setAttribute("download", file_path?.substr(file_path.lastIndexOf("/") + 1));
  a.setAttribute("target", "_blank");
  // a.setAttribute("target", "_self")
  a.setAttribute("rel", "noopener noreferrer");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const mutateSelection = (selection: SELECTION_TYPES[], id: string | number) =>
  selection.map((element) => {
    if (id === "none") {
      return {
        ...element,
        selected: element.id === id ? !element.selected : false,
      };
    }

    if (element.id === "none") element.selected = false;
    if (element.id === id)
      return {
        ...element,
        selected: !element.selected,
      };

    return element;
  });

export const formatMobileChange = (e: any, name: string) => {
  const old_value = e.target.value;
  const value = old_value;
  const phone = parsePhoneNumberFromString(value, "US");

  return phone && phone.formatInternational();
};

export function isLetter(str) {
  return str.length === 1 && str.match(/[a-z]/i) && str.toLowerCase() != str.toUpperCase();
}
export const handleMobileChange = (
  e: any,
  name: string,
  setFieldValue,
  format?: "International" | "NATIONAL" | "IDD" | "INTERNATIONAL"
) => {
  const old_value = e.target.value;

  if (old_value.length > 13) return;

  const value = old_value;
  const phone = parsePhoneNumberFromString(value, "US");

  setFieldValue(name, phone && phone.format(format));
};

export const listOfDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const days = {
  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
};

export const daysWithIndex = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};
// sort days according correctly
export const sortDays = (days: ({ day: string } | any)[], list: string[]) => {
  const DAY_OF_WEEK: number = 6;

  const sortedList = list.slice(DAY_OF_WEEK).concat(list.slice(0, DAY_OF_WEEK));
  return days.sort((a, b) => {
    if (sortedList.indexOf(a.day) > sortedList.indexOf(b.day)) return 1;
    if (sortedList.indexOf(a.day) < sortedList.indexOf(b.day)) return -1;
    return 0;
  });
};

export const sortDaysFromMonday = (days: string[]) => {
  days.sort(function sortByDay(a, b) {
    let day1 = a.toLowerCase();
    let day2 = b.toLowerCase();
    return daysWithIndex[day1] - daysWithIndex[day2];
  });

  return days;
};

export const SIZES = ["M", "S", "L", "XL", "XXL", "XXXL"];
// deep trims an object
export const deepTrim = (values) => {
  Object.keys(values).map(
    (k) =>
      (values[k] =
        typeof values[k] === "string"
          ? values[k].trim()
          : typeof values[k] === "object"
          ? deepTrim(values[k])
          : values[k])
  );
  return values;
};

export const handleNumberValue = (e: any, name: string, setFieldValue) => {
  const value = e.target.value;
  if (isNaN(Number(value))) return null;

  setFieldValue(name, value);
};

export const handleTimeChange = (e, name, length = 12, setFieldValue) => {
  const { value } = e.target;
  if (isNaN(Number(value)) || Number(value) > length) return;
  setFieldValue(name, value);
};

export const parseUrl = (url: string) =>
  url
    ? JSON.parse(
        '{"' + url.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      )
    : {};

export const getDaysRange = (date1, date2) => {
  const date = [];
  if (date1 && date2) {
    const days = Math.abs(moment(date2).diff(moment(date1), "days")) + 1;
    for (let i = 0; i < days; i++) {
      date.push(moment(date1).add(i, "days").format("DD MMM YYYY"));
    }
    return date;
  }

  return date;
};

export const padTime = (time: number, precision = 2) => time.toString().padStart(precision, "0");

//convert 24 to 12
export function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value

    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM

    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  return time.join(""); // return adjusted time or original string
}

export const displayWorksHours = (
  Workhours: ISDCWorkDays,
  param?: { fixedPrecision?: number; hourRange?: "24" | "12" }
): {
  day: string;
  hours: {
    start: string;
    end: string;
  };
}[] => {
  let hours = Workhours ? Object.entries(Workhours) : [];

  // sort days
  hours = hours.sort((a, b) => {
    //@ts-ignore
    const firstDay = days[a?.[0]] || "";
    //@ts-ignore
    const secondDay = days[b?.[0]] || "";
    if (moment(firstDay, "ddd dddd").weekday() > moment(secondDay, "ddd dddd").weekday()) return 1;
    if (moment(firstDay, "ddd dddd").weekday() < moment(secondDay, "ddd dddd").weekday()) return -1;
    return 0;
  });

  let i = 0;
  const nDays = Object.keys(days);

  return hours.reduce((a, o) => {
    let daylabel = o[0] ? String(o[0]) : "";
    let workHours = o[1] as ISDCWorkPeriod;

    if (!workHours.open) return a;

    daylabel = daylabel || nDays[i];

    let start = `${padTime(workHours.startHour24, param?.fixedPrecision)}:${padTime(
      workHours.startMinute24
    )}`;

    let end = `${padTime(workHours.endHour24, param?.fixedPrecision)}:${padTime(
      workHours.endMinute24
    )}`;

    const display = {
      day: capitalizeFirstLetter(daylabel),
      hours: {
        start:
          param?.hourRange === "24"
            ? `${start}  ${getAMOrPM(workHours.startHour24, workHours.startMinute24)}`
            : tConvert(start),
        end:
          param?.hourRange === "24"
            ? `${end}  ${getAMOrPM(workHours.endHour24, workHours.endMinute24)}`
            : tConvert(end),
      },
    };
    a.push(display);
    i++;
    return a;
  }, []);
};

// Set Eastern Timezone as default
export const defaultTimeZone = "America/Los_Angeles";

export function capitalizeFirstLetter(string: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function getAMOrPM(hour: number, minute = 0) {
  return moment(`10/11/2020 ${hour}:${minute}:00`).format("A");
}

export const validateAndUpload = (input: File, callback, error) => {
  const URL = window.URL || window.webkitURL;
  const file = input;
  if (file) {
    const image = new Image();
    image.onload = () => callback(image);
    image.onerror = error;
    image.src = URL.createObjectURL(file);
  }
};

/**
 *
 * @param url to redirect to
 */
export function redirect_blank(url: string) {
  var a = document.createElement("a");
  a.target = "_blank";
  a.href = url;
  a.click();
}

/**
 * settle all promises
 */
export const settlePromiseAsync = <T = {}>(
  result: (ThunkAction<Promise<any>, undefined, undefined, Actions> | Promise<any>)[]
): Promise<T> =>
  allSettled(result)
    .then((res: { status: string; data?: any }[]) => {
      const completeResult = res.filter((el) => el.status === "fulfilled");
      if (completeResult.length >= result.length) return Promise.resolve(completeResult);
      else return Promise.reject(res);
    })
    .catch((err) => Promise.reject(err));

export const sortSize = (a: ISDCItem, b: ISDCItem) => {
  if (a.size.toUpperCase() > b.size.toUpperCase()) {
    return -1;
  }
  if (b.size.toUpperCase() > a.size.toUpperCase()) {
    return 1;
  }
  return 0;
};

/**
 * gets due date from rush option
 * @param optionText
 * @param orderDate
 */
export const getDueDateFromRushOption = (optionText?: string, orderDate?: number | Date) => {
  if (!optionText) return null;

  const regex = /(?:\+.*)\+/g;
  const [, day] = regex.exec(optionText) || [];

  if (!day) return null;

  return moment(orderDate).add(Number(day), "days").toDate();
};

/**
 * validates a type file if it matches a file type
 * @param file
 * @param match
 */
export function validateFileType(file: File, match: string[]) {
  const filetype = file.type;
  if (match.includes(filetype.toLocaleLowerCase())) return true;
  return false;
}

export function addZeroes(num) {
  const dec = num.split(".")[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
}

/**
 *
 * converts pounds to ounce and pounds
 * @param weightInPounds
 */
export const convertPoundsToPoundsAndOunces = (weightInPounds) => {
  const weightsplit = weightInPounds.toString().split(".");
  let pounds = Number(weightsplit[0]) || 0;
  let ounce = Number("0." + weightsplit[1]) * 16 || 0;

  return { pounds, ounce };
};

export const getDigitFromString = (string: string) => Number(string.match(/\d+/)[0]);

/**
 *
 * reform sizes of @typedef ISDCInternalStatusSize to @interface ProductionStatusValueType
 */
export const formatSizesToSizeObject = <T extends ISDCInternalStatusSize | EditItem>(sizes: T[]) =>
  sizes.reduce(function (result, item) {
    var key = item.size;
    result[key] = item;
    return result;
  }, {});

/**
 * get averaging rating
 * @param rating
 */
export const average = (rating) => {
  const total = rating.reduce((prev, current) => {
    return prev + current;
  }, 0);

  if (total === 0) {
    return 0;
  }

  let sum = 0;
  let k = 1;
  rating.forEach((item) => {
    sum += item * k;
    k++;
  });
  const r = sum / total;
  return Number(r.toFixed(1));
};
/**
 *
 * @param filteredValues
 * displaying filter tags on order tables
 */
export const displaytagOptions = (filteredValues: FilterValue) =>
  filteredValues &&
  Object.entries(filteredValues)

    // map through each object and format text to tags
    .map((el) => {
      const title = el[0];
      const value = el[1];
      if (value === "All") return null;

      let element = {
        id: title,
        text: `${title}: ${value}`,
        tagStyle: {
          backgroundColor: "#F0F4F8",
          color: "#243B53",
        },
      };

      if (title === "order" && !isEmpty(value["start"]) && !isEmpty(value["end"])) {
        element.text = `Order Date: ${moment(value["start"]).format(DATE_FORMAT) || "N/A"} -  ${
          moment(value["end"]).format(DATE_FORMAT) || "N/A"
        }`;

        return element;
      } else if (title === "due" && !isEmpty(value["start"]) && !isEmpty(value["end"])) {
        element.text = `Due Date: ${moment(value["start"]).format(DATE_FORMAT) || "N/A"} -  ${
          moment(value["end"]).format(DATE_FORMAT) || "N/A"
        }`;

        return element;
      } else if (title === "delivery" || title === "status") {
        if (tags[`${value}`]?.text) {
          element.text = `${title}: ${tags[`${value}`]?.text}`;
          return element;
        }
        return null;
      }

      return null;
    })
    .filter(Boolean)
    .filter((el) => el["text"] !== "");

export const getFileExtention = (filename) => (filename ? filename.split(".").pop() : "");

export const isFileExtentionImage = (filename) =>
  filename
    ? ["JPG", "JPE", "BMP", "GIF", "PNG", "JPEG"].includes(String(filename).toUpperCase())
    : false;

export const parseDigits = (string: string | number) => {
  string = typeof string !== "string" ? `${string}` : string ?? "";
  return (string.match(/\d+/g) || []).join("");
};

export function onUploadingProgress(progressEvent: any): Promise<number> {
  var percentCompleted = Math.round((progressEvent?.loaded * 100) / progressEvent?.total - 10);
  return Promise.resolve(percentCompleted);
}

export const textToSentenceCase = (text: string): string => {
  if (!text || typeof text !== "string") return "";
  return text.charAt(0)?.toUpperCase() + text.slice(1)?.toLowerCase();
};

export const maskFileName = (name: string) => {
  if (!name) return name;

  const SHOW_LENGTH = 10;
  if (name.length <= SHOW_LENGTH) {
    return name;
  } else {
    var masked =
      name.substring(SHOW_LENGTH, 0) +
      "..." +
      name.substring(name.length - SHOW_LENGTH, name.length);
    return masked;
  }
};

export const parsePercent = (number: number, compare: number) =>
  parseFloat(Math.abs(number > 0 ? (number / compare) * 100 : 0).toFixed(2));

export const getTimes = (interval = 30, dateTime = 24) => {
  let x = interval; //minutes interval
  let times = []; // time array
  let tt = 0; // start time

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = ("0" + (hh % dateTime)).slice(-2) + ":" + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }

  return times;
};

/**
 * @param {date|moment} start The start date
 * @param {date|moment} end The end date
 * @param {string} type The range type. eg: 'days', 'hours' etc
 */
export function getRange(startDate, endDate, type) {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i < diff; i++) {
    range.push(
      moment(startDate)
        .add(i + 1, type)
        .toDate()
    );
  }
  return range;
}

/**
 * get days in a year
 * @param year
 */
export function daysInYear(year?: any) {
  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    // Leap year
    return 366;
  } else {
    // Not a leap year
    return 365;
  }
}

export const isMonth = (i: number) => Math.floor(i / moment().daysInMonth()) >= 1; // compare days in month putting 29 days into consideration
export const isWeek = (i: number) => Math.floor(i / 7) !== 0; // compare day per week
export const isYear = (i: number) => Math.floor(i / daysInYear()) >= 1; // compare days per year taking leap year into consideration

/***
 *
 * get timezone from adddress
 */
export const getTimeZoneFromAddress = (address: string) => {
  return new Promise(async (resolve, reject) => {
    const geo = new (window as any).google.maps.Geocoder();
    const timezone = await new Promise((inresolve, inreject) => {
      geo.geocode({ address: address }, async function (results, status) {
        if (status == (window as any).google.maps.GeocoderStatus.OK) {
          const place = results[0];

          const lat = place?.geometry?.location?.lat();
          const long = place?.geometry?.location?.lng();
          const timeZoneResult = await axios.get(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=1331766000&key=AIzaSyA_PM1zL83hV9v4wNtkcyatqPtv3oKfFds`
          );
          const timeZone = timeZoneResult.data?.timeZoneId;

          inresolve(timeZone);
        } else {
          inreject("No timezone found");
        }
      });
    }).catch((err) => reject(err));
    resolve(timezone ?? null);
  });
};

export const snakeCaseToNormal = (string: string) =>
  string ? String(string).split("_").join(" ") : "";

/**
 *
 * @param object
 * @param prev_object
 */
export const fromDotStringToObject = (
  object: any[],
  prev_object?: { [s: string]: unknown } | ArrayLike<unknown>
) => {
  if (!Array.isArray(object) || !object.length) return {};

  const val = object[1];
  const key = object[0];
  let prev_unfound_val = prev_object
    ? (Object.entries(prev_object).find((m) => m[0] === key.split(".")[0]) as [any, any])
    : [];

  let pre_value = prev_unfound_val?.length
    ? {
        [prev_unfound_val[0]]: prev_unfound_val[1],
      }
    : {};

  return String(key).split(".").length > 1 ? _.set(pre_value, key, val) : {};
};

/**
 * form snake case array to camelCase arra object
 */
export const formSnakeCaseToCamelCase = (result: any[]) => {
  return result.map((o) => {
    const value = Object.entries(o).reduce((a, _o) => {
      const val = _o[1];
      const stringToObjectValue = fromDotStringToObject(_o, a);

      return {
        ...a,
        [camelCase(_o[0])]: val,
        ...stringToObjectValue,
      };
    }, {});
    return value;
  });
};

export const getStateFunc = (getStateFunc: () => any, targetState: string | number) => {
  const state = getStateFunc();
  return state[targetState];
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const formatCurrency = (value: number) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  } as any);

  return currencyFormatter.format(value || 0);
};

export const poundToOunces = (pound: number) => {
  return pound * 16;
};

export const ouncesToPound = (ounces: number) => {
  return ounces / 16;
};

export const prettyItemSizes = (
  items: {
    sizes: any[];
  }[]
) => {
  if (!Array.isArray(items)) return "";

  return items
    .reduce((a, o) => {
      const texts = o.sizes.reduce((a, element) => {
        a += `, ${element["size"]}:${element["qty"]}`;
        return a;
      }, "");
      a += texts;
      return a;
    }, "")
    .replace(",", "");
};

export const states = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export const generateCSV = (data: AnyObject[]) => {
  const csvData = [];
  if (data.length) {
    let heads = Object.keys(data[0]);
    csvData.push(heads.join(","));
    data.forEach((obj) => {
      const values = heads.map((key) => obj[key]);
      csvData.push(values.join(","));
    });
  }
  return csvData.join("\n");
};

export const downloadCSV = (data: string, filename?: string) => {
  let anchor = document.createElement("a");
  anchor.href = `data:text/csv:charset=utf-8,${encodeURI(data)}`;
  anchor.target = "_blank";
  anchor.download = filename ? `${filename}.csv` : "download.csv";
  anchor.click();
};

export const PRINTING_METHODS_TITLE = {
  silk_screen: "Silk screen",
  direct_to_garment_light_and_dark: "Direct to garment - light and dark",
  direct_to_garment_light_only: "Direct to garment - light only",
  vinyl_cutter: "Vynil cutter",
  printable_vinyl: "Printable Vynil",
  direct_to_film: "Direct-to-film",
  sublimation: "Sublimation",
  embroidery: "Embroidery",
};

export const getStartAndEndDateFromPeriod = (period: string): { start: string; end: string } => {
  let end, start;
  end = DateTime.now().endOf("day").toISODate();
  switch (period?.toLowerCase()) {
    case "today":
    case "1d":
    case "1 day":
    case "today":
      // start = moment().subtract(1, "days").toISOString();
      start = DateTime.now().startOf("day").toISODate();
      break;
    case "7days":
    case "7 days":
    case "1w":
      // start = moment().subtract(7, "days").toISOString();
      start = DateTime.now().plus({ days: -7 }).startOf("day").toISODate();
      break;
    case "15days":
    case "15 days":
    case "15d":
      start = DateTime.now().plus({ days: -15 }).startOf("day").toISODate();
      break;
    case "30days":
    case "30 days":
    case "30d":
      start = DateTime.now().plus({ days: -30 }).startOf("day").toISODate();
      break;
    case "60days":
    case "60 days":
    case "60d":
      start = DateTime.now().plus({ days: -60 }).startOf("day").toISODate();
      break;
    case "90days":
    case "90 days":
    case "90d":
      start = DateTime.now().plus({ days: -90 }).startOf("day").toISODate();
      break;
    case "all time":
    case "all":
      start = DateTime.fromMillis(0).toISODate(); //moment(0).toISOString();
      break;
    default:
      start = DateTime.now().plus({ days: -7 }).startOf("day").toISODate();
      break;
  }

  return { start, end };
};

/**
 * This method accept a delivery method, from the following:
 * pickup | rush_pickup | super_pickup | 
 * car | rush_car | super_rush_car | 
 * shipping | rush | super_rush.
 * 
 * It returns, "pickup" for the argument that includes pickup 
 * It returns, "car" for the argument that includes car
 * it returns, "shipping" for rest.
 */
export const getMethodValue = (value: string) => {
  if (value) {
    const lowerCasedValue = value.toLocaleLowerCase();
    if (lowerCasedValue.includes("pickup")) return "pickup";
    else if (lowerCasedValue.includes("car")) return "car";
    else return "shipping";
  }
  return "";
}

export const removeSpecialCharacter = (str: string) => {
  const formattedString = str?.replace(/[^\w\s]/gi, '');

  return formattedString;
}

export const autoExpand = (field: any) => {
  // Reset field height
  field.style.height = "inherit";

  // Get the computed styles for the element
  let computed = window.getComputedStyle(field);

  // Calculate the height
  let height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

  field.style.height = !field.value ? field.style.minHeight : height + "px";
};
